import React, { useContext } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link, graphql } from "gatsby"
import Banner from "../../components/hero/banner-hometeam.js"

import Hero from "../../components/hero/pipefy-hero.js"
import { StaticImage } from "gatsby-plugin-image"

import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap"

import ItConsulting from "../../components/pipefy.js"

import PartnerSwiper from "../../components/swiper/swiper-partner.js"

import Testimonials2 from "../../components/swiper/testimonial-swiper.js"

import UnlockEnterprise from "../../components/unlock-enterprise.js"
import Cases from "../../components/cases.js"

import CaseStudySection from "../../components/header/header-casestudy-section.js"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2> */}
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Card.Header
      className={isCurrentEventKey ? "accordion-active" : "accordion-collapsed"}
    >
      <button
        aria-label="btn"
        type="button"
        onClick={decoratedOnClick}
        className={`m-0 border-0 bg-transparent w-100 text-left ${
          isCurrentEventKey ? "text-primary-gradient" : ""
        }`}
      >
        <p className="h5 text-left toggle-btn">{children}</p>
        <div>
          <div className="btn-plus-minus" />
        </div>
      </button>
    </Card.Header>
  )
}

const PipefyPage = props => {
  const { data } = props
  const siteTitle = props.data.site.siteMetadata.title
  return (
    <Layout location={props.location} title={siteTitle}>
      <Seo
        title="Pipefy Partners | Quandary Consulting Group"
        description="Looking for a Pipefy Partner? We help businesses (large and small) build, test, and deploy custom Pipefy apps that drive measurable impact."
      />
      <div id="main">
        <Hero />
        <PartnerSwiper />
        <div id="it-consult" className="it-consult-scroll">
          <ItConsulting />
        </div>
        <section
          style={{
            background: "#fff",
            padding: "3rem 0",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 results">
                <h2>Explore Our Pipefy Knowledge Base</h2>
                <p className="mt-4">
                  Our experts regularly share insights and best practices to
                  help you maximize the potential of Pipefy in your operations.
                </p>
                <p className="font-weight-bolder">You’ll learn how to:</p>
                <ul>
                  <li>Automate complex workflows​</li>
                  <li>Develop custom integrations​</li>
                  <li>Implement best practices for process automation</li>
                  <li>And more…</li>
                </ul>
                <Link
                  className="btn btn-primary btn-arrow mt-4"
                  to="/knowledge-base/"
                >
                  Explore the Knowledge Base
                </Link>
              </div>
              <div className="col-lg-6 align-self-center pt-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="https://cdn.sanity.io/images/55lcecww/production/a5d56b28e7e41861931d67d6996c5a92c43c0dd6-1208x490.png"
                  alt="Pipefy"
                  layout="constrained"
                  style={{ height: "14rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
            </div>
          </div>
        </section>
        <Testimonials2 />
        <CaseStudySection />
        <CaseComponent dataSrc={data.allSanityCaseStudy} />
        <Banner />
        <UnlockEnterprise />
        <section
          className="bg-case-study slogan-section"
          style={{
            paddingTop: "5rem",
            paddingBottom: "5rem",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto text-center">
                <div>
                  <h2 className="text-white">
                    Take Your Pipefy Automation to the Next Level (Without
                    Exhausting Your Team)
                  </h2>
                  <Link
                    to="/contact/"
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                    style={{ marginTop: "auto" }}
                  >
                    Let's Chat
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default PipefyPage

export const pipefyQuery = graphql`
  query pipefyQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
